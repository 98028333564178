<template>
  <div>
    <div v-if="type == 101">
      <div class="compont_p">
        我的退货单
        <!-- <div class="btn flex_c_c" @click="type = 102">添加退货</div> -->
      </div>
      <el-form :inline="true" :model="form" style="margin-top: 20px">
        <el-form-item label="订单编号:">
          <el-input v-model="form.order_id" placeholder="订单编号"></el-input>
        </el-form-item>
        <el-form-item label="退货单编号:">
          <el-input v-model="form.thd_no" placeholder="退货单编号"></el-input>
        </el-form-item>
        <el-form-item label="退货内容:">
          <el-input v-model="form.thnr" placeholder="退货内容"></el-input>
        </el-form-item>
        <el-form-item label="退货原因:">
          <el-select v-model="form.thyy" placeholder="请选择">
            <el-option v-for="item in options" :key="item.title" :label="item.title" :value="item.title"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="custombtn" @click="getReturn">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" class="mytable">
        <el-table-column prop="order_id" label="订单编号" align="center" />
        <el-table-column prop="orderno" label="退货单编号" align="center" />
        <el-table-column prop="thyy" label="退货原因" align="center" />
        <el-table-column prop="total_price" label="货物价格" align="center" />
        <el-table-column prop="tkzt" label="退款状态" align="center" />
        <el-table-column prop="addtime" label="添加时间" align="center" />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="check flex_c_c" @click="handleClick(scope.row)">查看</div>
          </template>
        </el-table-column>
      </el-table>

      <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
    </div>

    <div v-if="type == 102">
      <div class="compont_p">
        添加退货
        <div class="btn flex_c_c btn1" @click="back">返回上级</div>
      </div>
      <div class="form_wrap">
        <el-form :model="formInline" label-width="110px" :rules="rules" ref="form">
          <el-form-item label="退货订单编号：">
            <el-input v-model="$route.params.orderno" disabled placeholder="退货订单编号" style="width: 220px"></el-input>
          </el-form-item>

          <el-form-item label="勾选退货商品：">
            <el-table :data="returnData" @selection-change="selectionChange">
              <el-table-column type="selection" width="55" :selectable="selectable"> </el-table-column>
              <el-table-column prop="title" label="商品名称"> </el-table-column>
              <el-table-column prop="manufacturer" label="生产企业"> </el-table-column>
              <el-table-column prop="specification" label="商品规格"> </el-table-column>
              <el-table-column prop="price" label="价格"> </el-table-column>
              <el-table-column prop="number" label="购买数量"> </el-table-column>
              <el-table-column prop="ck_number" label="出库数量"> </el-table-column>
              <el-table-column label="退货数量" width="200">
                <template slot-scope="{ row }">
                  <el-input-number v-model="row.number1" :min="row.ck_number == 0 ? 0 : 1" :max="Number(row.ck_number)"></el-input-number>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="退货原因：" prop="reason_arr">
            <el-select v-model="formInline.reason_arr" multiple placeholder="请选择">
              <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传凭证：" prop="images">
            <upload :limit="4" @successUrl="successUrl" @deleteImage="deleteImage"></upload>
          </el-form-item>
          <el-form-item label="退货原因描述：">
            <el-input type="textarea" style="width: 520px" :rows="5" v-model="formInline.reason"></el-input>
          </el-form-item>
        </el-form>
        <div class="sub_btn flex_c_c" @click="onSubmit">提交退货</div>
      </div>
    </div>

    <el-dialog title="详情" :visible.sync="dialogVisible" width="60%">
      <el-descriptions :column="1">
        <el-descriptions-item label="退货单编号">{{ info.orderno }}</el-descriptions-item>
        <el-descriptions-item label="退款金额">{{ info.tkprice }}</el-descriptions-item>
        <el-descriptions-item label="退货状态">{{ info.status_str }}</el-descriptions-item>
        <el-descriptions-item label="退货图片" v-if="info.image && info.image.length">
          <div v-for="item in info.image" :key="item.id">
            <el-image style="width: 100px; height: 100px" :src="item.url" :preview-src-list="srcList"> </el-image>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="退货原因"> {{ info.reason }} </el-descriptions-item>
        <el-descriptions-item label="管理员回复">{{ info.reply }} </el-descriptions-item>
      </el-descriptions>
      <el-table :data="info.goods" style="width: 100%">
        <el-table-column label="商品图片">
          <template slot-scope="scope">
            <el-image :src="scope.row.image.url"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="商品名称"> </el-table-column>
        <el-table-column prop="specification" label="商品规格"> </el-table-column>
        <el-table-column prop="scqy" label="生产企业"> </el-table-column>
        <el-table-column prop="price" label="价格"> </el-table-column>
        <el-table-column prop="number" label="数量"> </el-table-column>
        <el-table-column prop="thsl" label="退货数量"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";
import upload from "@/components/upload.vue";
export default {
  components: {
    pagination,
    upload,
  },
  data() {
    return {
      type: 101,
      form: {
        order_id: "",
        thd_no: "",
        thnr: "",
        thyy: "",
      },
      returnData: [],
      formInline: {
        id: "",
        reason: "",
        images: [],
        goods: [],
        reason_arr: [],
      },
      tableData: [],
      options: [],
      total: 0,
      page: 1,
      page_size: 10,
      info: {},
      dialogVisible: false,
      srcList: [],
      selectionList: [],
      rules: {
        reason_arr: [{ required: true, message: "请选择退货原因", trigger: "change" }],
        images: [{ required: true, message: "请上传凭证", trigger: "change" }],
      },
    };
  },
  created() {
    this.getReturn();
    this.$api("account.getReturnReason").then((res) => {
      this.options = res.data;
    });
    if (this.$route.params.orderno) {
      this.type = 102;
      this.getDetail(this.$route.params.orderno);
    }
  },
  methods: {
    getReturn() {
      this.$api("account.getReturn", {
        ...this.form,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.listTotal;
      });
    },
    handleClick(row) {
      this.$api("account.getReturnDel", { id: row.id }).then((res) => {
        this.info = res.data;
        this.srcList = this.info.image.map((e) => e.url);
        this.dialogVisible = true;
      });
    },
    getDetail(id) {
      this.$api("account.getOrderDel", { id }).then((res) => {
        res.data.goods.forEach((e) => (e.number1 = 0));
        this.returnData = res.data.goods;
      });
    },
    back() {
      this.type = 101;
      this.getReturn();
    },
    selectable(row) {
      if (row.ck_number == 0) {
        return false;
      } else {
        return true;
      }
    },
    selectionChange(e) {
      this.selectionList = e;
    },
    successUrl(data) {
      this.formInline.images.push({ id: data.id });
    },
    deleteImage(file) {
      let index = 0;
      this.formInline.images.forEach((e, i) => {
        if (e.id == file.id) index = i;
      });
      this.formInline.images.splice(index, 1);
    },
    onSubmit() {
      if (!this.selectionList.length) {
        this.$message.error("请选择退货商品");
        return;
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.formInline.goods = [];
          this.selectionList.forEach((e) => {
            this.formInline.goods.push({ id: e.id, ck_number: e.number1 });
          });
          this.$api("account.applyReturn", { ...this.formInline, id: this.$route.params.id }).then(() => {
            this.$message.success("申请退货成功");
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getReturn();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getReturn();
    },
  },
};
</script>

<style lang="less" scoped>
.compont_p {
  position: relative;
  .btn {
    position: absolute;
    left: 120px;
    top: 0;
    width: 76px;
    height: 30px;
    background: #ff4c4c;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .btn1 {
    background: @themeColor;
  }
}
.check {
  cursor: pointer;
  width: 48px;
  height: 30px;
  background: @themeColor;
  border-radius: 4px;
  color: #ffffff;
}
.form_wrap {
  margin-top: 20px;
}
.sub_btn {
  margin-left: 100px;
  width: 76px;
  height: 30px;
  background: #ff4c4c;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
</style>
<style lang="less">
.mytable {
  .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
