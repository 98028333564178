<template>
  <div class="upload">
    <el-upload
      ref="upload"
      :accept="accept"
      :action="action"
      class="upload-content"
      :class="{ hide: hideUpload }"
      :data="data"
      :disabled="disabled"
      :file-list="fileList"
      :headers="headers"
      :limit="limit"
      :list-type="listType"
      :multiple="multiple"
      :name="name"
      :on-change="handleChange"
      :on-error="handleError"
      :on-exceed="handleExceed"
      :on-preview="handlePreview"
      :on-progress="handleProgress"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
    >
      <template #trigger>
        <div v-if="listType === 'picture-card'" class="icon_wrap">
          <i class="el-icon-plus" />
          <span>点击上传</span>
        </div>
        <div v-else class="icon_wrap">
          <el-button class="custombtn" size="small" type="primary">选取文件上传</el-button>
        </div>
      </template>
      <el-dialog append-to-body title="查看大图" :visible.sync="dialogVisible">
        <div>
          <el-image :src="dialogImageUrl" />
        </div>
      </el-dialog>
    </el-upload>
  </div>
</template>

<script>
// import _ from "lodash";
// import { mapGetters } from "vuex";
import { LgetItem } from "../utils/storage";

export default {
  name: "VabUpload",
  props: {
    // 单张图片上传路径
    url: {
      type: String,
      default: "",
    },
    // 多张图片上传路径
    list: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "array",
    },
    uploadkey: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      default: 3,
    },
    accept: {
      type: String,
      default: "image/png, image/jpeg",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      hideUpload: false,
      dialogVisible: false,
      dialogImageUrl: "",
      action: "https://app.demo.wiscode.cn/file/file/save",
      headers: {},
      fileList: [],
      picture: "picture",
      imgNum: 0,
      imgSuccessNum: 0,
      imgErrorNum: 0,
      typeList: null,
      // title: '上传',
      dialogFormVisible: false,
      data: {},
    };
  },
  computed: {
    // percentage() {
    //   if (this.allImgNum === 0) return 0;
    //   return _.round(this.imgNum / this.allImgNum, 2) * 100;
    // },
    token() {
      return LgetItem("token") || "";
    },
    listType() {
      if (this.accept == "image/png, image/jpeg") {
        return "picture-card";
      } else {
        return "text";
      }
    },
    textname() {
      if (this.accept == "image/png, image/jpeg") {
        return "图片";
      } else {
        return "文件";
      }
    },
  },
  watch: {
    list() {
      console.log(this.list, "this.list");
      this.fileList = this.list.map((item) => {
        return {
          url: item,
        };
      });
      if (this.fileList.length >= this.limit) {
        this.hideUpload = true;
      }
    },
    url(val) {
      if (val) {
        this.fileList = [{ url: val }];
        this.hideUpload = true;
      } else {
        this.fileList = [];
        this.hideUpload = false;
      }
    },
  },
  created() {
    if (this.url) {
      this.fileList = [{ url: this.url }];
      this.hideUpload = true;
    }
    if (this.list.length) {
      this.fileList = this.list.map((item) => {
        return {
          url: item,
        };
      });
      if (this.fileList.length >= this.limit) {
        this.hideUpload = true;
      }
    }
    // this.headers['Authorization'] = `Bearer ${this.token}`
    this.headers["token"] = `${this.token}`;
  },
  methods: {
    handleProgress() {
      this.loading = true;
      this.show = true;
    },
    handleChange(file, fileList) {
      this.hideUpload = fileList.length >= this.limit;
      if (file.size > 1048576 * this.size) {
        fileList.filter((item) => item !== file);
        this.fileList = fileList;
      } else {
        this.allImgNum = fileList.length;
      }
    },
    handleSuccess(response, file, fileList) {
      this.imgNum = this.imgNum + 1;
      this.imgSuccessNum = this.imgSuccessNum + 1;

      const data = response.data;
      this.fileList = fileList;
      this.$emit("successUrl", data, this.uploadkey);

      setTimeout(() => {
        this.loading = false;
        this.show = false;
      }, 1000);
    },
    handleError() {
      this.imgNum = this.imgNum + 1;
      this.imgErrorNum = this.imgErrorNum + 1;
      this.$message.error("上传失败");

      setTimeout(() => {
        this.loading = false;
        this.show = false;
      }, 1000);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.hideUpload = fileList.length >= this.limit;

      this.imgNum = this.imgNum - 1;
      this.allNum = this.allNum - 1;
      this.$emit("deleteImage", this.uploadkey);
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      if (this.accept == "image/png, image/jpeg") {
        this.dialogVisible = true;
      }
    },
    handleExceed() {
      this.$message.error(`上传失败，当前限制上传 ${this.limit} 个文件`);
    },
    handleShow(data) {
      // this.title = '上传'
      this.data = data;
      this.dialogFormVisible = true;
    },
    handleClose() {
      this.fileList = [];
      this.picture = "picture";
      this.allImgNum = 0;
      this.imgNum = 0;
      this.imgSuccessNum = 0;
      this.imgErrorNum = 0;
      this.headers["Authorization"] = `Bearer ${this.token}`;
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.upload {
  // height: 500px;
  ::v-deep.el-upload-list__item-thumbnail {
    /* 图片在方框内显示长边 */
    object-fit: contain;
  }
  .icon_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    span {
      display: inline-block;
      font-size: 13px;
      line-height: 1;
    }
  }
  ::v-deep .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
  .upload-content {
    .el-upload__tip {
      display: block;
      height: 30px;
      line-height: 30px;
    }

    ::v-deep {
      // .el-upload-list__item {
      //   transition: none;
      // }
      .el-list-leave-active {
        transition: none;
      }
      .el-list-leave-to {
        transition: none;
      }
      .el-upload--picture-card {
        width: 128px;
        height: 128px;
        margin: 3px 8px 8px 8px;
        border: 2px dashed #c0ccda;
      }

      .el-upload-list--picture {
        margin-bottom: 20px;
      }

      .el-upload-list--picture-card {
        .el-upload-list__item {
          width: 128px;
          height: 128px;
          margin: 3px 8px 8px 8px;
        }
      }
    }
  }
}
</style>
